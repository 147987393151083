export const creditUnions = {
    londonCapital:          "London Capital Credit Union",
    serveAndProtect:        "Serve and Protect Credit Union",
    commSave:               "Commsave Credit Union",
    southManchester:        "South Manchester Credit Union",
    pennyPost:              "Penny Post Credit Union",
    lewishamPlus:           "Lewisham Plus Credit Union",
    lewishamAndBromley:     "Lewisham + Bromley Credit Union",
    firstRate:              "First Rate Credit Union",
    waveCommunity:          "Wave Community Bank",
    planeSaver:             "Planesaver Credit Union",
    tranSave:               "Transave Credit Union",
    clockwise:              "Clockwise Credit Union",
    citySave:               "CitySave Credit Union",
    gmb:                    "GMB Credit Union",

}

export const prizeSaverDraw = [
    {
        date: "2024-10",
        prizes: [
            {
                amount: 5000,
                creditUnions: [
                    creditUnions.pennyPost
                ]
            },
            {
                amount: 50,
                creditUnions: [
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.lewishamAndBromley,
                    creditUnions.londonCapital,
                    creditUnions.pennyPost,
                    creditUnions.pennyPost,
                    creditUnions.serveAndProtect,
                    creditUnions.tranSave
                ]
            },
            {
                amount: 20,
                creditUnions: [
                    creditUnions.citySave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.gmb,
                    creditUnions.londonCapital,
                    creditUnions.planeSaver,
                    creditUnions.southManchester,
                    creditUnions.tranSave,
                    creditUnions.tranSave
                ]
            }
        ]
    },
    {
        date: "2024-09",
        prizes: [
            {
                amount: 5000,
                creditUnions: [
                    creditUnions.commSave
                ]
            },
            {
                amount: 50,
                creditUnions: [
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.londonCapital,
                    creditUnions.londonCapital,
                    creditUnions.lewishamAndBromley,
                    creditUnions.pennyPost,
                    creditUnions.planeSaver,
                    creditUnions.planeSaver,
                    creditUnions.serveAndProtect,
                    creditUnions.tranSave
                ]
            },
            {
                amount: 20,
                creditUnions: [
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.londonCapital,
                    creditUnions.londonCapital,
                    creditUnions.pennyPost,
                    creditUnions.serveAndProtect,
                    creditUnions.waveCommunity
                ]
            }
        ]
    },
    {
        date: "2024-08",
        prizes: [
            {
                amount: 5000,
                creditUnions: [
                    creditUnions.lewishamAndBromley
                ]
            },
            {
                amount: 50,
                creditUnions: [
                    creditUnions.firstRate,
                    creditUnions.londonCapital,
                    creditUnions.pennyPost,
                    creditUnions.pennyPost,
                    creditUnions.planeSaver,
                    creditUnions.planeSaver,
                    creditUnions.serveAndProtect,
                    creditUnions.serveAndProtect,
                    creditUnions.serveAndProtect,
                    creditUnions.waveCommunity
                ]
            },
            {
                amount: 20,
                creditUnions: [
                    creditUnions.firstRate,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.londonCapital,
                    creditUnions.planeSaver,
                    creditUnions.planeSaver,
                    creditUnions.planeSaver,
                    creditUnions.tranSave
                ]
            }
        ]
    },
    {
        date: "2024-07",
        prizes: [
            {
                amount: 5000,
                creditUnions: [
                    creditUnions.waveCommunity
                ]
            },
            {
                amount: 50,
                creditUnions: [
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.lewishamPlus,
                    creditUnions.planeSaver,
                    creditUnions.serveAndProtect,
                    creditUnions.tranSave,
                    creditUnions.tranSave
                ]
            },
            {
                amount: 20,
                creditUnions: [
                    creditUnions.clockwise,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.londonCapital,
                    creditUnions.londonCapital,
                    creditUnions.planeSaver,
                    creditUnions.serveAndProtect,
                    creditUnions.serveAndProtect,
                    creditUnions.serveAndProtect,
                    creditUnions.serveAndProtect
                ]
            }
        ]
    },
    {
        date: "2024-06",
        prizes: [
            {
                amount: 5000,
                creditUnions: [
                    creditUnions.londonCapital
                ]
            },
            {
                amount: 50,
                creditUnions: [
                    creditUnions.londonCapital,
                    creditUnions.londonCapital,
                    creditUnions.londonCapital,
                    creditUnions.londonCapital,
                    creditUnions.serveAndProtect,
                    creditUnions.serveAndProtect,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.southManchester
                ]
            },
            {
                amount: 20,
                creditUnions: [
                    creditUnions.serveAndProtect,
                    creditUnions.serveAndProtect,
                    creditUnions.pennyPost,
                    creditUnions.pennyPost,
                    creditUnions.commSave,
                    creditUnions.commSave,
                    creditUnions.southManchester,
                    creditUnions.lewishamPlus,
                    creditUnions.firstRate,
                    creditUnions.londonCapital
                ]
            }
        ]
    },
]
