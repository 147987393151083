<template>
  <div class="home">
    <PrizeDrawResults/>
    <NewsAlerts/>
    <AGM v-if="false" />
    <b-row>
      <b-col cols="12" md="12" lg="8">
        <Calculator/>
      </b-col>
      <b-col cols="12" md="4" class="pl-md-0 d-sm-block d-md-none d-lg-block">
          <a href="https://cashbox.cuaccount.com/join/" target="_blank">
            <video playsinline autoplay muted loop class="w-100 mb-2 rounded">
              <source
                  src="/video/home-join-us.mp4"
                  type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </a>
      </b-col>
    </b-row>
    <b-card-group deck class="mb-3">
      <b-card
          class="text-center"
          title="Save with Cash Box"
          img-src="/tiles/cash.png"
          img-alt="Savings"
          img-top
      >
        <b-button to="/savings" variant="outline-success">Find Out More</b-button>
      </b-card>
      <b-card
          class="text-center"
          title="Loans from Cash Box"
          img-src="/tiles/pool.png"
          img-alt="Loans"
          img-top
      >
        <b-button to="/loans" variant="outline-success">Find Out More</b-button>
      </b-card>
      <b-card
          class="text-center"
          title="Christmas Savings"
          img-src="/tiles/xmas.png"
          img-alt="Christmas Savings"
          img-top
      >
        <b-button to="/christmas-savings" variant="outline-success">Find Out More</b-button>
      </b-card>
    </b-card-group>
    <b-card-group deck class="mb-3">
      <!--      <b-card-->
      <!--          class="text-center"-->
      <!--          title="SoundPound Loan"-->
      <!--          img-src="/tiles/soundpound.png"-->
      <!--          img-alt="SoundPoundLoan"-->
      <!--          img-top-->
      <!--      >-->
      <!--        <b-button to="/loans/soundpound-loan" variant="outline-success">Find Out More</b-button>-->
      <!--      </b-card>-->
      <b-card
          class="text-center"
          title="Bee Network Annual Bus Pass"
          img-src="/tiles/bee-network.png"
          img-alt="Bee Network Annual Bus Pass"
          img-top
      >
        <b-button to="/bee-network-annual-bus-pass" variant="outline-success">Find Out More</b-button>
      </b-card>
      <b-card
          class="text-center"
          title="Prize Saver"
          img-src="/tiles/prizesaver.png"
          img-alt="Prize Saver"
          img-top
      >
        <b-button to="/savings/prize-saver" variant="outline-success">Find Out More</b-button>
      </b-card>
      <b-card
          class="text-center"
          title="Payroll Deduction Scheme"
          img-src="/tiles/work-meeting.png"
          img-alt="Payroll Deduction Scheme"
          img-top
      >
        <b-button to="/payroll-deduction-scheme" variant="outline-success">Find Out More</b-button>
      </b-card>
      <b-card
          class="text-center"
          title="Help & Support"
          img-src="/tiles/help.png"
          img-alt="Help & Support"
          img-top
      >
        <b-button to="/help-and-support" variant="outline-success">Find Out More</b-button>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import NewsAlerts from "@/components/NewsAlerts";
import Calculator from "@/components/Calculator";
import AGM from "@/components/AGM";
import PrizeDrawResults from "@/components/PrizeDrawResults.vue";
export default {
  name: 'Home',
  components: {AGM, Calculator, NewsAlerts, PrizeDrawResults},
  metaInfo() {
    return {
      title: 'Home | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style>
.home .card-deck .card .card-body {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
